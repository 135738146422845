<template>
  <div>
    <vue-phone-number-input
      v-model="number"
      dark-color="#283145"
      color="#7367f0"
      :dark="isDark"
      @update="updated"
      placeholder=""
      no-example
    />
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: { VuePhoneNumberInput },
  props: ["value"],
  data() {
    return {
      number: this.value,
    };
  },
  watch: {
    value() {
      this.number = this.value;
    },
  },
  computed: {
    isDark() {
      const { skin } = useAppConfig();
      return skin.value === "dark";
    },
  },
  methods: {
    updated({ e164 }) {
      this.$emit("input", e164);
    },
  },
};
</script>

<style lang="scss">
@import "~vue-phone-number-input/dist/vue-phone-number-input.css";
.dark-layout .vue-phone-number-input * {
  border-color: #404656 !important;
  caret-color: #fff !important;
}
.vue-phone-number-input * {
  border-color: #d8d6de !important;
  caret-color: #000 !important;
}
</style>
