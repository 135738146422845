var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse',[_c('app-collapse-item',{staticClass:"mx-0",attrs:{"title":_vm.$t('fields.address'),"isVisible":true}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.country'),"label-for":"address-country"}},[_c('validation-provider',{attrs:{"name":"address.country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:"iso2",attrs:{"id":"address-country","label":"name","options":_vm.countries,"reduce":function (val) { return val.iso2; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
var flag = ref.flag;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(flag))]),_c('span',[_vm._v(_vm._s(name))])]}},{key:"option",fn:function(ref){
var name = ref.name;
var flag = ref.flag;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(flag))]),_c('span',[_vm._v(_vm._s(name))])]}}],null,true),model:{value:(_vm.value.country),callback:function ($$v) {_vm.$set(_vm.value, "country", $$v)},expression:"value.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.line_one'),"label-for":"address_line-one"}},[_c('validation-provider',{attrs:{"name":"address.line1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_line-one","name":"address.line1","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.value.line1),callback:function ($$v) {_vm.$set(_vm.value, "line1", $$v)},expression:"value.line1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.line_two'),"label-for":"address_line-two"}},[_c('validation-provider',{attrs:{"name":"address.line2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_line-two","name":"address.line2","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.value.line2),callback:function ($$v) {_vm.$set(_vm.value, "line2", $$v)},expression:"value.line2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.city'),"label-for":"address_city"}},[_c('validation-provider',{attrs:{"name":"address.city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_city","name":"address.city","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.value.city),callback:function ($$v) {_vm.$set(_vm.value, "city", $$v)},expression:"value.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.state-region'),"label-for":"address_state-region"}},[_c('validation-provider',{attrs:{"name":"address.state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_state-region","name":"address.state","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.value.state),callback:function ($$v) {_vm.$set(_vm.value, "state", $$v)},expression:"value.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.postal-zip-code'),"label-for":"address_postal-code"}},[_c('validation-provider',{attrs:{"name":"address.postal_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_postal-code","name":"address.postal_code","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.value.postal_code),callback:function ($$v) {_vm.$set(_vm.value, "postal_code", $$v)},expression:"value.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }