import axios from "@/libs/axios";


export async function index(query) {
    try {
        const { data } = await axios.get('/api/v1/organizations', { params: query });
        return { data };
    } catch (error) {
        return { error: null }
    }
}

export async function show(id) {
    try {
        const { data: { data }
        } = await axios.get(`/api/v1/organizations/${id}`)
        return { data }
    } catch (error) {

    }
}


export async function store(formData) {
    Promise.reject('Not implemented');
    try {
        const { data } = await axios.post("/api/v1/organizations", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}

export async function update(id, formData) {
    formData.append('_method', 'PUT')
    try {
        const { data } = await axios.post(`/api/v1/organizations/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}