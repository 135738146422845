<template>
  <app-collapse>
    <app-collapse-item
      class="mx-0"
      :title="$t('fields.address')"
      :isVisible="true"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            :label="$t('fields.country')"
            label-for="address-country"
          >
            <validation-provider #default="{ errors }" name="address.country">
              <v-select
                id="address-country"
                v-model="value.country"
                label="name"
                key="iso2"
                :options="countries"
                :reduce="(val) => val.iso2"
              >
                <template #selected-option="{ name, flag }">
                  <span class="mr-1">{{ flag }}</span>
                  <span>{{ name }}</span>
                </template>

                <template #option="{ name, flag }">
                  <span class="mr-1">{{ flag }}</span>
                  <span>{{ name }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6"></b-col>
        <!-- LINE ONE -->
        <b-col cols="6">
          <b-form-group
            :label="$t('fields.line_one')"
            label-for="address_line-one"
          >
            <validation-provider #default="{ errors }" name="address.line1">
              <b-form-input
                id="address_line-one"
                v-model="value.line1"
                name="address.line1"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('fields.line_two')"
            label-for="address_line-two"
          >
            <validation-provider #default="{ errors }" name="address.line2">
              <b-form-input
                id="address_line-two"
                v-model="value.line2"
                name="address.line2"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group :label="$t('fields.city')" label-for="address_city">
            <validation-provider #default="{ errors }" name="address.city">
              <b-form-input
                id="address_city"
                v-model="value.city"
                name="address.city"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('fields.state-region')"
            label-for="address_state-region"
          >
            <validation-provider #default="{ errors }" name="address.state">
              <b-form-input
                id="address_state-region"
                v-model="value.state"
                name="address.state"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('fields.postal-zip-code')"
            label-for="address_postal-code"
          >
            <validation-provider
              #default="{ errors }"
              name="address.postal_code"
            >
              <b-form-input
                id="address_postal-code"
                v-model="value.postal_code"
                name="address.postal_code"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import VSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["value"],
  components: { VSelect, ValidationProvider, AppCollapse, AppCollapseItem },
  computed: {
    ...mapGetters(["countries"]),
  },
  methods: {
    ...mapActions(["fetchCountries"]),
  },
  created() {
    this.fetchCountries();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
