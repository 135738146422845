<template>
  <div
      v-if="src || editable"
      class="upload-button"
      tabindex="0"
      :class="{'invalid-file': hasFileError}"
      :title="editable ? 'Add a photo' : null"
  >
    <template v-if="!src">
      <feather-icon
          class="plus-icon"
          icon="PlusIcon"
          @click="$refs.file.click()"
      />
      <span class="plus-icon-title">{{
          $t("common.actions.select_photo")
        }}</span>
    </template>
    <template v-else>
      <!-- Main Image -->
      <img
          :class="!isUploaded || loading ? 'pending-upload' : ''"
          :src="src"
          v-lightbox
          :data-original="original"
          :data-group="group"
      />

      <!-- Clear button -->
      <b-button
          v-if="loading || !isUploaded"
          size="lg"
          class="trigger-button shadow-none"
          variant="primary"
          :disabled="loading"
          @click="uploadPhoto"
      >
        <span>
          <b-spinner v-if="loading" small/>
          {{ $t("common.actions.upload") }}
        </span>
      </b-button>

      <!-- Cropper Button -->
      <b-button
          v-if="!isUploaded"
          class="btn-icon rounded-circle btn-sm crop-button"
          variant="gradient-info"
          title="Clear new photo"
          v-b-modal="modalId"
      >
        <feather-icon icon="CropIcon"/>
      </b-button>
    </template>

    <input
        type="file"
        accept=".png,.jpeg,.jpg"
        ref="file"
        style="display: none"
        @change="photoSelected"
    />

    <b-button
        v-if="!loading && editable && src"
        class="btn-icon rounded-circle btn-sm clear-button"
        variant="gradient-danger"
        title="Clear new photo"
        @click="clearSelectedPhoto"
    >
      <feather-icon icon="XIcon"/>
    </b-button>
    <span class="upload-button-footer">
      <slot name="footer"></slot>
    </span>
    <b-modal :id="modalId" centered size="lg" @ok="confirmCrop">
      <div>
        <cropper
            class="cropper"
            image-restriction="stencil"
            :auto-zoom="true"
            :src="src"
            @change="onCrop"
        />
      </div>
    </b-modal>
  </div>
</template>


<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import FileValidationMixins from "@core/mixins/FileValidationMixins";

export default {
  name: "avatar-uploader",
  components: {FeatherIcon, Cropper},
  props: ["avatar", "editable", "loading", "original", "group", "deleteConfirm"],
  mixins: [FileValidationMixins],
  data() {
    return {
      isUploaded: true,
      file: null,
      modalId: "modal-" + Math.random(),
      croppedFile: null,
    };
  },
  computed: {
    src() {
      return this.avatar ?? (this.file && URL.createObjectURL(this.file));
    },
  },
  methods: {
    photoSelected(event) {
      this.fileErrors = []
      const file = event.target.files[0];
      if (file) {
        if (!this.validateFile(file)) {
          this.file = file;
          this.isUploaded = false;
        } else {
          this.showFileToast()
        }
      }
    },
    uploadPhoto() {
      if (this.file) {
        this.isUploaded = true;
        this.$emit("change", {file: this.file});
      }
    },
    deleteConfirmAlert() {
      this.$swal({
        title: this.$t('avatar_uploader.title'),
        text: this.$t('avatar_uploader.text'),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t("common.actions.confirm"),
        cancelButtonText: this.$t("common.actions.cancel"),
        customClass: {
          cancelButton: "btn btn-primary",
          confirmButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.handleDelete()
        }
      });
    },
    clearSelectedPhoto() {
      this.file = null;
      this.$refs.file.value = "";
      // this.src = null;
      if (this.isUploaded) {
        if (this.deleteConfirm) {
          this.deleteConfirmAlert()
        } else {
          this.handleDelete()
        }
      }
    },
    handleDelete() {
      this.$emit("change", {file: null});
    },
    confirmCrop() {
      this.file = this.croppedFile;
    },
    onCrop({coordinates, canvas}) {
      canvas.toBlob((file) => (this.croppedFile = file), this.file.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  --trigger-button-height: 1.8rem;

  width: 100% !important;
  max-width: 192px;
  aspect-ratio: 1/1;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.crop-button,
.clear-button {
  position: absolute;
  top: -0.5rem;
}

.crop-button {
  left: -0.5rem;
}

.clear-button {
  right: -0.5rem;
}

.trigger-button {
  z-index: 20;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  .container-box {
    width: 100% !important;
  }
}

.upload-button {
  position: relative;
  background-color: #0003;
  border-radius: 0.5rem;
  background-blend-mode: color-burn;
  transition: background-color ease 0.2s;

  .plus-icon {
    cursor: pointer;
  }

  .plus-icon,
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;

    border-radius: 0.5rem;
  }

  .plus-icon {
    padding: 20%;
    opacity: 0.8;
    transition: opacity ease-in 0.2s, padding ease-in 0.2s;
  }

  .plus-icon-title {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #0004;
    transition: background-color ease 0.2s;

    .plus-icon {
      padding: 18%;
      opacity: 0.9;
      transition: opacity ease-in 0.2s, padding ease-in 0.2s;
    }
  }

  img {
    object-fit: cover;
  }
}

.upload-button-footer {
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
}

img.pending-upload {
  height: calc(100% - var(--trigger-button-height) - 0.2rem);
  width: calc(100% - var(--trigger-button-height) - 0.2rem);
  margin-left: calc(100% - 0.7rem);
  margin-right: calc(100% - 0.7rem);

  & + button {
    height: var(--trigger-button-height);
    line-height: var(--trigger-button-height);
    font-size: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0.1rem;
  }
}

.cropper {
  max-width: 100%;
  max-height: 600px;
  background: #000;
}
</style>