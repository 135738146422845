<template>
  <b-card :title="$t('organization.form.title-create')">
    <!-- form -->
    <validation-observer ref="form">
      <b-form class="mt-2">
        <b-row>
          <b-col md="3" class="d-flex justify-content-center">
            <span style="width: 100%">
              <avatar-uploader
                class="mx-auto"
                :avatar="avatar.url"
                @change="selectAvatar"
                :editable="true"
              />
            </span>
          </b-col>
          <b-col md="9">
            <div class="d-flex flex-column">
              <b-col xs="12" sm="12" md="6">
                <!-- Organization Name -->
                <b-form-group
                  :label="$t('fields.organization.name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="form.name"
                      name="name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <!-- Organization Name -->
                <b-form-group :label="$t('fields.organization.email')" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      name="email"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <!-- Organization phone -->
                <b-form-group :label="$t('fields.phone')" label-for="phone">
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <phone-number v-model="form.phone" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col xs="12" sm="12" md="6"></b-col>
              <b-col cols="12" class="mx-0 px-0">
                <address-form v-model="form.address" />
              </b-col>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col sm="12" md="4" class="ml-auto">
            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="validateForm"
              :disabled="loading"
            >
              <b-spinner small v-if="loading" />
              <span class="sr-only" v-if="loading">
                {{ $t("general.loading") }}</span
              >
              <span v-if="!loading">
                <template v-if="organizationId">
                  {{ $t("organization.form.update") }}
                </template>
                <template v-else>
                  {{ $t("organization.form.create") }}
                </template>
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { store, update, show } from "@/api/organizations.api";
import AvatarUploader from "@/components/Person/AvatarUploader.vue";
import AddressForm from "@/components/AddressForm.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  name: "OrganizationsForm",
  components: {
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
    AvatarUploader,
    AddressForm,
    PhoneNumber,
  },
  props: ["initialOrganizationId", "onSuccess"],
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        phone: "",
        address: {
          country: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
        },
      },
      avatar: { url: null, file: null },
      required,
      email,
    };
  },
  watch: {
    async organizationId() {
      this.$refs.form.reset();
      if (this.organizationId) {
        this.fetchData();
      }
    },
  },
  computed: {
    organizationId() {
      return this.initialOrganizationId ?? this.$route.params?.id ?? null;
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;

      const { data } = await show(this.organizationId);

      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] != "object") {
          this.form[key] = data[key];
        } else {
          Object.keys(this.form[key]).forEach((subKey) => {
            this.form[key][subKey] = data[key] ? data[key][subKey] ?? "" : "";
          });
        }
      });

      /* Object.keys(this.form).forEach((key) => (this.form[key] = data[key])); */
      this.avatar.url = data.avatar?.thumbnail;
      this.loading = false;
    },
    validateForm(redirect = true) {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;
        this.persist(redirect);
      });
    },
    async persist(redirect) {
      this.loading = true;

      const formData = new FormData();

      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] != "object") {
          formData.append(key, this.form[key]);
        } else {
          Object.keys(this.form[key]).forEach((subKey) => {
            formData.append(`${key}[${subKey}]`, this.form[key][subKey]);
          });
        }
      });
      if (!this.avatar.url && !this.avatar.file) {
        formData.append("clear_avatar", true);
      }
      if (this.avatar.file) {
        formData.append("avatar", this.avatar.file);
      }

      const result = this.organizationId
        ? await update(this.organizationId, formData)
        : await store(formData);

      if (result.error) {
        this.$refs.form.setErrors(result.error);
      } else {
        if (redirect) {
          if (this.onSuccess) {
            this.onSuccess();
          } else {
            this.$router.push({
              name: this.organizationId
                ? "organization-show"
                : "organization-edit",
              params: { id: this.organizationId ?? result.data.id },
            });
          }
        }
      }

      this.loading = false;
    },
    selectAvatar({ file }) {
      this.avatar.file = file;
      if (this.avatar && !file) {
        this.avatar.url = null;
      }
      if (this.avatar.file) {
        this.validateForm(false);
      }
    },
  },
  mounted() {
    if (this.organizationId) {
      this.fetchData();
    }
  },
};
</script>
